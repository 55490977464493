import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {Helmet} from "react-helmet";
import "../main.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import styled from 'styled-components';
import { getFinancingOptions, createFinancingOptions, getDocumentEstimateData, elasticSearchFetchDoc, elasticSearchUpdateDocOrTemp, getContactById, generatePdf, sendEmail } from '../graphql/queries';
import Amplify, { API } from 'aws-amplify';
import config from "../aws-exports";
import { isEmpty, parseNumber, formatPhoneNumber, calculateData, formatter, calculateAdjQuantity, calculateNetCost } from '../Components/CommonComponents/helper';
import moment from "moment";
import * as queryString from "query-string";
import { navigate } from "gatsby";
import Tooltip from "rc-tooltip";
import Loader from '../assets/loading.gif';
import Loader2 from '../assets/pageload.gif';
import print from '../assets/print-icon.svg';
import email from '../assets/email.svg';
import file from '../assets/file.svg';
import edit from '../assets/edit.svg';
import calculator from '../assets/calculator.svg';
import arrowDown from '../assets/arrow-down.svg';
import { HiScissors } from "react-icons/hi";
// import SunLightLogo from '../assets/logo-sunlight-financial.png';
import CameraLogo from '../assets/dollar-bill.png'
import ToastBox from "../Components/Toast/Toast";
import SendEmailModal from '../Components/Modals/SendEmailModal';
import { browserName } from "react-device-detect";
import ReactToPrint from 'react-to-print';
import {FaCircleNotch} from "react-icons/fa";
import NotesIcon from "../assets/Notes_Icon.svg";
import  secureLocalStorage  from  "react-secure-storage";
Amplify.configure(config);

export default function Pdftable(props) {
  const {consoleLog} = require('../Components/commonFunctions.js');
  const dispatch = useDispatch();
  const url_params = queryString.parse(props.location.search);
  const invoice = parseInt(url_params.invoice);
  const [defContactFirstName , setdefContactFirstName] = useState("");
  const [defContactLastName , setdefContactLastName] = useState("");
  const [defContactEmail , setdefContactEmail] = useState("");
  const [defContactPhoneNo , setdefContactPhoneNo] = useState("");
  const [companyName , setCompanyName] = useState("");
  const [financingOptions, setFinancingOptions] = React.useState([]);

  useEffect(() => {
    setdefContactFirstName(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_estimate_contact_first_name)
    setdefContactLastName(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_estimate_contact_last_name)
    setdefContactEmail(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_estimate_contact_email)
    setdefContactPhoneNo(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_estimate_contact_phone)
    setCompanyName(JSON.parse(secureLocalStorage.getItem("client_data")).company)
  },[]);

  async function fetchFinancingOptions(estData) {
    if (typeof estData.financing_options != 'undefined' && estData.financing_options != '' && estData.financing_options != null) {
      consoleLog("fetchFinancingOptions>", JSON.parse(estData.financing_options));
      setFinancingOptions(JSON.parse(estData.financing_options));
      return JSON.parse(estData.financing_options);
    }else{
      if(typeof url_params.doc_id != 'undefined'){
        consoleLog("fetchFinancingOptions doc_id>", url_params.doc_id);
        const get_financing_options = await API.graphql({
          query: getFinancingOptions,
          variables: {
            doc_id: url_params.doc_id
          },
        });
        consoleLog("fetchFinancingOptions get_financing_options>", get_financing_options);
        let data = JSON.parse(get_financing_options?.data?.getFinancingOptions);
        consoleLog("fetchFinancingOptions data>", data);
        if(data.length > 0){
          setFinancingOptions(data);
          return data;
        }else{
          let userId = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
          let f_options = [{"user_id": userId, "doc_id":url_params.doc_id, "percentage":0.00, "months":0, "is_active":"Y", "is_default":"N"},{"user_id": userId, "doc_id":url_params.doc_id, "percentage":0.00, "months":0, "is_active":"Y", "is_default":"N"},{"user_id": userId, "doc_id":url_params.doc_id, "percentage":0.00, "months":0, "is_active":"Y", "is_default":"N"}];
          setFinancingOptions(f_options);
          return f_options;
        }
      }
    }
  }

  async function getDocument() {
    let data = {};
    let estimateData = {};
    const docId = url_params.doc_id ? url_params.doc_id : null;

    if (docId) {

      const templateRecordElastic = await API.graphql({
        query: elasticSearchFetchDoc,
        variables: {
          doc_id: docId
        }
      });

      estimateData = JSON.parse(JSON.parse(templateRecordElastic.data.elasticSearchFetchDoc)).data;
      consoleLog("data from elastic...", estimateData);
      if (Object.keys(estimateData).length == 0) {

        const templateRecord = await API.graphql({
          query: getDocumentEstimateData,
          variables: {
            doc_id: docId
          }
        });

        estimateData = JSON.parse(templateRecord.data.getDocumentEstimateData);
        consoleLog("data from db...", estimateData);
      }

      estimateData.doc_id = docId;

      let f_options = await fetchFinancingOptions(estimateData);
      estimateData.financing_options = f_options;

      consoleLog('fetchFinancingOptions>>>', estimateData);

      consoleLog('financingOptions is_active', estimateData.financing_options[0].is_active +", " + estimateData.financing_options[1].is_active + ", " + estimateData.financing_options[2].is_active  );


      setStData(estimateData);

    } else {
      estimateData = stData;
    }

    calculateTotalPages(estimateData);
    data = calculateData(estimateData);

    if (data.totalQuantity <= 0) {
      return navigate(`/template?template_id=${estimateData.template_id}&doc_type_id=${estimateData.doc_type_id}&doc_id=${estimateData.doc_id}`, { state: { error: true } });
    }

    let contactInfo = {};
    if(stData.contact_info) {
      contactInfo = JSON.parse(stData.contact_info);
      contactInfo.zone_name = contactInfo && contactInfo.name ? contactInfo.name : '';
      setContact(contactInfo);
      consoleLog("contact info from elastic search.....",contactInfo);
    }else {
      const getContact = await API.graphql({
        query: getContactById,
        variables: {
          contact_id: estimateData.contact_id
        }
      });
      consoleLog('contact info from db.....', getContact);
      contactInfo = JSON.parse(getContact.data.getContactById);
      setContact(contactInfo && contactInfo[0]);
      consoleLog("contact info from db.....",contactInfo);
    }

    let ownerInfo = {};
    if(stData.property_owner_info) {
      ownerInfo = JSON.parse(stData.property_owner_info);
      setPropertyOwner(ownerInfo);
      consoleLog("property owner info from elastic search.....",ownerInfo);
    }else {
      const getPropertyOwner = await API.graphql({
        query: getContactById,
        variables: {
          contact_id: estimateData.owner_contact_id
        }
      });
      consoleLog('property owner info from db.....', getPropertyOwner);
      ownerInfo = JSON.parse(getPropertyOwner.data.getContactById);
      consoleLog('property owner info from db.....', ownerInfo);
      if(ownerInfo.length){
        ownerInfo[0].name = ownerInfo && ownerInfo[0] ? `${ownerInfo[0].first_name} ${ownerInfo[0].last_name}` : '';
        consoleLog('property owner info from db.....', ownerInfo);
        setPropertyOwner(ownerInfo && ownerInfo[0]); 
      }
    }

    setTaxes(data.taxes);
    setMarkups(data.markups)
    setSubTotal(data.subTotal);
    console.log("total_data" , data.total);
    setTotal(data.total);
    
    console.log('xxx>>>', data);
    console.log("total" , total);
    
    setLoading(false);
    pdfDownloadx(url_params.invoice, contactInfo, ownerInfo).then(
      function(response) {
        consoleLog('pdfDownloadx response>>>', response);
        if(typeof response != 'undefined') {
          const getUrl = JSON.parse(response.data.generatePdf);
          let pdf = getUrl.message.Location;
          let stDataCopy = stData;
          if (url_params.invoice == 1) {
            stDataCopy.invoice_pdf = pdf;
          } else {
            stDataCopy.pdf = pdf;
          }
          //update the document on elasticsearch
          let temp = JSON.stringify(stDataCopy);
          API.graphql({
            query: elasticSearchUpdateDocOrTemp,
            variables: {
              id: stData.doc_id,
              index: 'prodocs-documents',
              template: temp
            },
          });
          setStData(stDataCopy);
          dispatch({ type: "TEMPLATE_STRUCTURE", value: stDataCopy });
          setDownload(false);
        }
      } 
    );
  }

  async function pdfDownloadx(invoice, contactInfo=[], ownerInfo=[]){
    let pdf;
    let data = {};

    if (invoice == 1) {
      pdf = stData.invoice_pdf;
    } else {
      pdf = stData.pdf;
    }
    consoleLog('pdfDownloadx invoice>>>', invoice);
    consoleLog('pdfDownloadx stData>>>', stData);
    consoleLog('pdfDownloadx pdf>>>', pdf);

    consoleLog('pdfDownloadx contactInfo>>>', contactInfo);
    // consoleLog('pdfDownloadx contactInfo>>>', Object.keys(contact).length);
    consoleLog('pdfDownloadx contact>>>', contact);
    let c_info = {};
    if(typeof contact == 'object' && Object.keys(contact).length != 0){
      consoleLog('pdfDownloadx contact if>>>',contact);
      c_info = contact;
    }else{
      consoleLog('pdfDownloadx contact else>>>', typeof contactInfo);
      if(Array.isArray(contactInfo)){
        c_info = contactInfo[0];
        consoleLog('pdfDownloadx contact if>>>', contactInfo[0]);
      }else{
        c_info = contactInfo;
        consoleLog('pdfDownloadx contact else>>>', contactInfo);
      }
    }
    consoleLog('pdfDownloadx c_info>>>', c_info);

    consoleLog('pdfDownloadx ownerInfo>>>', ownerInfo);
    consoleLog('pdfDownloadx ownerInfo>>>', Object.keys(propertyOwner).length);
    consoleLog('pdfDownloadx propertyOwner>>>', propertyOwner);
    let o_info = {};
    if(typeof propertyOwner == 'object' && Object.keys(propertyOwner).length !== 0){
      consoleLog('pdfDownloadx propertyOwner if>>>',propertyOwner);
      o_info = propertyOwner;
    }else{
      consoleLog('pdfDownloadx propertyOwner else>>>', typeof ownerInfo);
      if(Array.isArray(ownerInfo)){
        o_info = ownerInfo[0];
        consoleLog('pdfDownloadx propertyOwner else if>>>',ownerInfo[0]);
      }else{
        o_info = ownerInfo;
        consoleLog('pdfDownloadx propertyOwner else else>>>',ownerInfo);
      }
    }
    consoleLog('pdfDownloadx o_info>>>', o_info);

    if (typeof pdf != 'undefined' && pdf != '') {
      consoleLog('pdfDownloadx abort download>>>');
    }else{
      consoleLog('pdfDownloadx start download>>>');
      if(typeof stData.structures != 'undefined'){
        setDownload(true);
        data.propertyOwner = o_info;
        data.contact = c_info;
        data.docDetail = stData;
        data.calcualatedData = calculateData(stData);
        data.invoice = invoice;
        data.client_default_data = JSON.parse(secureLocalStorage.getItem("client_data"));
        consoleLog('pdfDownloadx start generation>>>', data);
        //generate the PDF
        const response = API.graphql({
          query: generatePdf,
          variables: {
            docData: JSON.stringify(data)
          }
        });
        return response;
      }else{
        consoleLog('pdfDownloadx abort generation>>>');
      }
    }
  }

  async function pdfDownload(sendMessage = 0) {
    let pdf;
    let data = {};

    if (invoice === 1) {
      pdf = stData.invoice_pdf;
    } else {
      pdf = stData.pdf;
    }
    consoleLog('pdfDownload>>>', pdf);

    if (sendMessage === 0) {
      setDownload(true);
    }

    if (isEmpty(pdf)) {
      data.propertyOwner = propertyOwner;
      data.contact = contact;
      data.docDetail = stData;
      data.calcualatedData = calculateData(stData);
      data.invoice = invoice;
      data.client_default_data = JSON.parse(secureLocalStorage.getItem("client_data"));
      consoleLog('pdfDownload start generation>>>', data);
      //generate the PDF
      const response = await API.graphql({
        query: generatePdf,
        variables: {
          docData: JSON.stringify(data)
        }
      });
      const getUrl = JSON.parse(response.data.generatePdf)
      pdf = getUrl.message.Location;

      if (invoice === 1) {
        stData.invoice_pdf = pdf;
      } else {
        stData.pdf = pdf;
      }

      //update the document on elasticsearch
      let temp = JSON.stringify(stData);
      API.graphql({
        query: elasticSearchUpdateDocOrTemp,
        variables: {
          id: stData.doc_id,
          index: 'prodocs-documents',
          template: temp
        },
      });

      dispatch({ type: "TEMPLATE_STRUCTURE", value: stData });
    }

    if(sendMessage === 1){
      setPdfUrl(pdf);
    }else {
      setDownload(false);
      window.open(pdf, "_blank");
    }
  }

  const emailPopup = async () => {
    setEmailPopupShow(true);
    pdfDownload(1);
  }

  async function sendDoc(emails, subject, message, url) { 

    let validEmail = true;
    let toEmails = emails.split(',');
    let client = JSON.parse(secureLocalStorage.getItem('client_data'));
    
    if(isEmpty(emails) || isEmpty(subject) || isEmpty(message)) {
      setError('Fields with * are required');
    }else if(isEmpty(url)) {
      setError('Something went wrong please try again!');
    }else {
      for (const email of toEmails) {
        if(!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email.trim()))) {
          validEmail = false;
          break;
        } 
      }
      
      if(validEmail) {
        setDocSend(true);

        const response = await API.graphql({
          query: sendEmail,
            variables: {
              subject: subject,
              to_email: emails,
              url_download: url,
              email_msg: message,
              client_email: client.email,
              client_id: client.client_id
            }
        }); 

        const result = JSON.parse(response.data.sendEmail);
        if(result.status === 422) {
          setMessage(result.message);
        }
        else if(result[0] && result[0].status === 'sent') {
          setMessage('Your document has been emailed to the requested recipients!');
          setMessageType('Success');
        }else {
          setMessage('We were unable to send the PDF document via email. Please try downloading and emailing the PDF manually.');
          setMessageType('Error');
        }
        setPdfUrl('');
        setShowMsg(true);
        setDocSend(false);
        setEmailPopupShow(false);
      }else {
        setError('Invalid Email address');
      }
    }
  }

  function redirectPage(invoice) {
    consoleLog('redirectPage');
    if(invoice == 1) {
      navigate('/pdftable?invoice=1&preview=estpdf&doc_id='+url_params.doc_id);
    }else {
      navigate('/pdftable?preview=estpdf&doc_id='+url_params.doc_id);
    }
    pdfDownloadx(invoice).then(
      function(response) {
        consoleLog('pdfDownloadx response>>>', response);
        consoleLog('pdfDownloadx invoice>>>', invoice);
        if(typeof response != 'undefined') {
          const getUrl = JSON.parse(response.data.generatePdf);
          let pdf = getUrl.message.Location;
          let stDataCopy = stData;
          if (invoice == 1) {
            stDataCopy.invoice_pdf = pdf;
          } else {
            stDataCopy.pdf = pdf;
          }
          //update the document on elasticsearch
          let temp = JSON.stringify(stDataCopy);
          API.graphql({
            query: elasticSearchUpdateDocOrTemp,
            variables: {
              id: stData.doc_id,
              index: 'prodocs-documents',
              template: temp
            },
          });
          setStData(stDataCopy);
          dispatch({ type: "TEMPLATE_STRUCTURE", value: stDataCopy });
          setDownload(false);
        }
      } 
    );
  }

  function calculateTotalPages(estimateData){
    let pages = 0;
    estimateData.structures.map((str) => {
      const toltalQuantity = str.categories.reduce((allTotal, cat) => {
        const sum = cat.line_items.length
        return allTotal + sum;
      }, 0);
      if(toltalQuantity > 0) {
        pages++;
      }
    });
    setTotalPages(pages);
  }

  const [stData, setStData] = React.useState(
    useSelector((state) => state.templateStructure)
  );
  const [loading, setLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const [subTotal, setSubTotal] = React.useState(0);
  const [taxes, setTaxes] = React.useState([]);
  const [markups, setMarkups] = React.useState([]);
  const [contact, setContact] = React.useState([]);
  const [propertyOwner, setPropertyOwner] = React.useState([]);
  const [download, setDownload] = React.useState(false);
  const [docSend, setDocSend] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [error, setError] = useState('');
  const [emailPopupShow, setEmailPopupShow] = useState(false);             
  const [pdfUrl, setPdfUrl] = useState('');

  const [defLogo, setDefLogo] = useState('//roofscope.com/app/view/theme/polyscope/image/logo.png#file_id=8');
  const [defColor, setDefColor] = useState('#da8227');
  
  const options = stData.options ? JSON.parse(stData.options) : null;

  const color = options && options.color ? options.color : defColor;


  consoleLog('my browser is', browserName);

  let pageCount = 0;
  const [totalPages, setTotalPages] = React.useState(0);
  
  const componentRef = useRef(null);

  useEffect(() => {
    let dLogo = JSON.parse(secureLocalStorage.getItem('client_data')).logo;
    if(dLogo){
      setDefLogo(dLogo);
      consoleLog('dLogo>>>', dLogo);
    }
    let dColor = JSON.parse(secureLocalStorage.getItem('client_data')).color;
    if(dColor){
      setDefColor(dColor);
      consoleLog('dColor>>>', dColor);
    }
    consoleLog('useEffect>>>');
    getDocument();
  }, []);

  
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=756, initial-scale=1, shrink-to-fit=no" />
        <title>Prodocs</title>
      </Helmet>
      { emailPopupShow && <SendEmailModal
        show={emailPopupShow}
        setModalShow={setEmailPopupShow}
        error={error}
        docSend={docSend}
        pdfUrl={pdfUrl}
        onSendDoc={sendDoc}
      /> }
      <ToastBox
          setShow={setShowMsg}
          show={showMsg}
          Text={message}
          Type={messageType}
          />
      {loading ? (
        <LoadingContainer>
          <img className="loader" src={Loader} />
        </LoadingContainer>
      ) : (
        <PDFS >
          <div className="mainDiv">
            <div className="downloadPDF-section no-print">
            <Tooltip
                        overlay={
                            <div
                            className="d-flex flex-column justify-content-center pdf-tooltip"
                            >
                              <p style={{ marginBottom: '0' , fontWeight: 'bold'}}>Edit</p>
                            </div>
                        }
                        placement="bottom"
                        >
                            <a href={`/template?template_id=${stData.template_id}&&doc_type_id=${stData.doc_type_id}&&doc_id=${stData.doc_id}`} className="button-section">
                <img src={edit} alt={'edit-icon'} />
              </a>
                    </Tooltip>
                        <ReactToPrint
                          trigger={() =>
                          <Tooltip
                            overlay={
                              <div className="d-flex flex-column justify-content-center pdf-tooltip">
                                <p style={{ marginBottom: '0' , fontWeight: 'bold'}}>Print</p>
                              </div>
                            }
                            placement="bottom"
                            >
                            <div className={browserName == 'Chrome' ? "button-section" : ""} >
                              <img src={print} alt={'print-icon'} />
                            </div>
                          </Tooltip>
                          }
                         
                          content={() => componentRef.current}
                          bodyClass="print-body"
                          pageStyle={printStyles}
                                                  />
              <Tooltip
                        overlay={
                            <div
                            className="d-flex flex-column justify-content-center pdf-tooltip"
                            >
                              <p style={{ marginBottom: '0' , fontWeight: 'bold'}}>Download</p>
                            </div>
                        }
                        placement="bottom"
                        >
              { download ? 
                <div className="button-section">
                  <FaCircleNotch size={22} className="awesome_spinner" style={{color:'white'}}/>
                </div> :
                <div className="button-section" onClick={() => pdfDownload(0)}>
                  <img src={arrowDown} alt={'arrowDown-icon'} />
                </div>
              } 
              </Tooltip>
              <Tooltip
                        overlay={
                            <div
                            className="d-flex flex-column justify-content-center pdf-tooltip"
                            >
                              <p style={{ marginBottom: '0' , fontWeight: 'bold'}}>Email</p>
                            </div>
                        }
                        placement="bottom"
                        >
              <div className="button-section" onClick={() => emailPopup()}>
                <img src={email} alt={'email-icon'} />
              </div>
              </Tooltip>
              {
                invoice === 1 ?
                <Tooltip
                overlay={
                    <div
                    className="d-flex flex-column justify-content-center pdf-tooltip"
                    >
                      <p style={{ marginBottom: '0' , fontWeight: 'bold'}}>Estimate</p>
                    </div>
                }
                placement="bottom"
                >
                <div className="button-slip" onClick={() => redirectPage(0)}>
                  <img src={calculator} alt={'calculator-icon'} />
                </div>
                </Tooltip>
               : 
               <Tooltip
               overlay={
                   <div
                   className="d-flex flex-column justify-content-center pdf-tooltip"
                   >
                     <p style={{ marginBottom: '0' , fontWeight: 'bold'}}>Invoice</p>
                   </div>
               }
               placement="bottom"
               >
                <div className="button-calculator" onClick={() => redirectPage(1)}>
                  <img src={file} alt={'file-icon'} />
                </div>
                </Tooltip>
              }
            </div>
            <MainDiv ref={componentRef}>
                {stData && stData.structures && stData.structures.map((item, index) => {
                  const toltalQuantity = item.categories.reduce((allTotal, cat) => {
                    const sum = cat.line_items.reduce((total, record) => {
                      return +total + +calculateAdjQuantity(record);
                    }, 0);
                    return parseFloat(+allTotal + +sum);
                  }, 0);

                  const strToltalPrice = item?.categories.reduce((allTotal, cat) => {
                    const sum = cat?.line_items.reduce((total, record) => {
                      return +total + +calculateNetCost(record);
                    }, 0);
                    return +allTotal + +sum;
                  }, 0);

                  consoleLog('strToltalPrice>>>', strToltalPrice);

                  if (toltalQuantity > 0) {
                    let count = 0;
                    ++pageCount;
                    return (
                    <Wrapper className="pdf-container" key={index} style={pageCount > 1 ? { marginTop: "65px"} : null}>
                    {pageCount == 1 && <>
                      <div className="container-pdf" style={{ marginTop: '20px' }}>
                        <div className="logo-pdf">
                          <img src={options?.logo ? options.logo : defLogo} alt="" />
                        </div>
                      </div>
                      <div class="header-contact">
                          {contact ?
                            <p style={{textAlign: 'center'}}>
                              {contact?.company ? contact?.company : ''}{ contact?.phone ? ' | '+formatPhoneNumber(contact?.phone) : ''}
                            </p>
                            :
                            <p style={{textAlign: 'center'}}>
                              { defContactPhoneNo ? formatPhoneNumber(defContactPhoneNo) : ''}
                            </p>
                          }
                      </div>
                      <div className="container-pdf m-bottom">
                        <div className="row w-100">
                          <div className="col-8 p-left">
                          {contact ? (
                                    <>
                                       <p className="estimate-pdf">&nbsp;</p>
                                      <table className="table custom-table-bordered ">
                                        <thead className="custom-th-width">
                                          <tr>
                                            <th
                                              scope="col"
                                              className="text-end-pdf custom-bg-grey-pdf"
                                            >
                                              PREPARED BY
                                            </th>
                                            <th
                                              scope="col"
                                              className="custom-bg-pdf-first th-contact-width"
                                            >
                                              {contact.first_name}{" "}
                                              {contact.last_name}
                                            </th>
                                          </tr>
                                          <tr>
                                            <th
                                              scope="col"
                                              className="text-end-pdf custom-bg-grey-pdf-middle"
                                            >
                                              EMAIL
                                            </th>
                                            <th
                                              scope="col"
                                              className="custom-bg-pdf-second"
                                            >
                                              {contact.email}
                                            </th>
                                          </tr>
                                          <tr>
                                            <th
                                              scope="col"
                                              className="text-end-pdf custom-bg-grey-pdf-last"
                                            >
                                              CLAIM #
                                            </th>
                                            <th
                                              scope="col"
                                              className="custom-bg-pdf-last"
                                            >
                                            {stData.claim_number}
                                            </th>
                                          </tr>
                                        </thead>
                                      </table>
                                    </>
                                  )
                                  : 
                                    <>
                                       <p className="estimate-pdf">&nbsp;</p>
                                      <table className="table custom-table-bordered ">
                                        <thead className="custom-th-width">
                                          <tr>
                                            <th
                                              scope="col"
                                              className="text-end-pdf custom-bg-grey-pdf"
                                            >
                                              PREPARED BY
                                            </th>
                                            <th
                                              scope="col"
                                              className="custom-bg-pdf-first th-contact-width"
                                            >
                                              {defContactFirstName}{" "}
                                              {defContactLastName}
                                            </th>
                                          </tr>
                                          <tr>
                                            <th
                                              scope="col"
                                              className="text-end-pdf custom-bg-grey-pdf-middle"
                                            >
                                              EMAIL
                                            </th>
                                            <th
                                              scope="col"
                                              className="custom-bg-pdf-second"
                                            >
                                              {defContactEmail}
                                            </th>
                                          </tr>
                                          <tr>
                                            <th
                                              scope="col"
                                              className="text-end-pdf custom-bg-grey-pdf-last"
                                            >
                                              CLAIM #
                                            </th>
                                            <th
                                              scope="col"
                                              className="custom-bg-pdf-last"
                                            >
                                            {stData.claim_number}
                                            </th>
                                          </tr>
                                        </thead>
                                      </table>
                                    </>
                                  }
                            {/* {contact && <><p className="pdf-title-pdf">{contact.company}</p>
                              {contact.address && <p className="pdf-desc-pdf">{contact.address}</p>}
                              {contact.address_2 && <p className="pdf-desc-pdf">{contact.address_2}</p>}
                              {contact.city && <p className="pdf-desc-pdf">{contact.city}, {contact.zone_name} {contact.postcode}</p>}
                              <p className="pdf-desc-pdf">Prepared By: {contact.first_name} {contact.last_name}</p>
                              <p className="pdf-desc-pdf">{formatPhoneNumber(contact.phone)}</p>
                              <p className="pdf-desc-pdf">{contact.email}</p></>} */}
                          </div>
                          <div className="col-4">
                            <p className="estimate-pdf-label">{invoice === 1 ? stData.invoice_label : stData.label==''||stData.label==null?<div>&nbsp;</div>:stData.label}</p>
                            <table className="table custom-table-bordered ">
                              <thead className="custom-th-width">
                                <tr>
                                  <th scope="col" className="text-end-pdf custom-bg-dark-pdf">DATE</th>
                                  <th scope="col" className="custom-bg-pdf-first w-100" style={{minWidth:'184px'}}>{moment().format("MM/DD/YYYY")}</th>
                                </tr>
                                <tr>
                                  <th scope="col" className="text-end-pdf custom-bg-dark-pdf-middle">ESTIMATE #</th>
                                  <th scope="col" className="custom-bg-pdf-second">{stData.ref_id}</th>
                                </tr>
                                <tr>
                                  <th scope="col" className="text-end-pdf custom-bg-dark-pdf-last" style={{borderBottom:'0px'}}>STRUCTURES</th>
                                  <th scope="col" className="custom-bg-pdf-last">{stData.structure_count}</th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                      </div>
                      {invoice === 1 ? (<>
                        <div className="container-pdf">
                          <div className="row w-100">
                            <div className="col-6 p-left">
                              <table className="table custom-table-bordered">
                                <thead className="custom-th-width">
                                  <tr>
                                    <th scope="col" className="text-end-pdf custom-bg-dark-pdf">AMOUNT DUE</th>
                                    <th scope="col" className="custom-bg-pdf" style={{minWidth:"371px"}}>${total}</th>
                                  </tr>
                                  <tr>
                                    <th scope="col" className="text-end-pdf custom-bg-dark-pdf-last">ENCLOSED</th>
                                    <th scope="col" className="custom-bg-pdf-last"></th>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="container-pdf cut-separator">
                          <div className="icon">
                            <HiScissors style={{ color: "#737f85" }} />
                          </div>
                          <div className="text">Please detach top portion and return with your payment</div>
                        </div>
                      </>) : null}
                      <div className="container-pdf">
                        <div className="w-100">
                          {/* <p className="pdf-details-pdf">
                            {propertyOwner.name ? propertyOwner.name : ''} {propertyOwner.email ? ' | '+propertyOwner.email : ''} {propertyOwner.phone ? ' | '+formatPhoneNumber(propertyOwner.phone) : ''}
                          </p> */}
                          {
                            stData.location && 
                          <p className="pdf-details-pdf loc-sec">
                          {stData.location}
                          </p>
                          }
                        </div>
                      </div>
                    </>}

                    <div className="w-100" style={{marginBottom:"50px"}}>
                      <div className="w-100">
                        <div className={`custom-pdf-bg-pdf w-100 header-title-main ${stData.location && index == 0 ? "border-radius-none" : ""}`}>
                          <div scope="col" className="text-start-pdf w-100 custom-light-color-pdf" >
                            <div className="width-80-l">{item.name}</div>
                            {options && parseInt(options.show_structure_price) === 1 && <div className="width-20-r">${strToltalPrice.toFixed(2)}</div>}
                            <div style={{ clear: 'both' }}></div>
                          </div>
                        </div>
                      </div>
                      {item.categories.map((category, category_index) => {

                        const catTotalQuatity = category.line_items.reduce((total, record) => {
                          // return total + parseNumber(record.adjusted_quantity);
                          return +total + +calculateAdjQuantity(record);
                        }, 0);

                        const catTotalPrice = category.line_items.reduce((total, record) => {
                          // return total + parseNumber(record.net_cost);
                          return +total + +calculateNetCost(record);
                        }, 0);

                        if (catTotalQuatity > 0) {
                          return (<div className="w-100 m-bottom" key={category_index}>
                            {options && parseInt(options?.show_categories) == 1 && <div className="custom-pdf-bg2-pdf w-100 header-title">
                              <div scope="col" className="text-start-pdf w-100 header-title">
                                <div className="width-80-l" style={{color:'white !important'}}>{category.name}</div>
                                {options && parseInt(options?.show_category_price) == 1 && <div className="width-20-r">${catTotalPrice.toFixed(2)}</div>}
                                <div style={{ clear: 'both' }}></div>
                              </div>
                            </div>}
                            <div className="w-100">
                              <div className="quantity-row-pdf">
                                <div className="table-pdf col-name b-top b-left table-header">ITEM NAME</div>
                                <div className="table-pdf b-top text-end-pdf table-header">QUANTITY</div>
                                <div className="table-pdf b-top text-end-pdf table-header">PRICE</div>
                                <div className="table-pdf b-top text-end-pdf table-header">AMOUNT</div>
                              </div>
                            </div>
                            {console.log('category' ,category)}
                            {category?.line_items.map((line_item, line_item_index) => {

                              let subLineItems = line_item?.sub_line_item;
                              if (!subLineItems || subLineItems == "[]") {
                                subLineItems = [];
                              }
                              if (typeof subLineItems == 'string') {
                                subLineItems = JSON.parse(subLineItems);
                                if (typeof subLineItems == 'string') {
                                  subLineItems = JSON.parse(subLineItems);
                                }
                              }
                              consoleLog('subLineItems>', subLineItems);
                              consoleLog('subLineItems>', typeof subLineItems);

                              if (calculateAdjQuantity(line_item) > 0) {
                                count++;
                                return (
                                <div className={`w-100 ${
                                  line_item_index % 2 == 0
                                    ? "bg-white"
                                    : "bg-grey"
                                }`} key={line_item_index}
                                ref={(node) => {
                                  if (node) {
                                    node.style.setProperty("page-break-inside", 'avoid', "important");
                                    node.style.setProperty("page-break-after", 'auto', "important");
                                  }
                                }}
                                >
                                  <div className="quantity-row-pdf">
                                    <div className="table-pdf col-name b-left">
                                      {line_item.name}
                                      {subLineItems?.length > 0 && <ul className="notes">
                                        {subLineItems?.map((note, note_index) => (
                                          <li key={note_index}>{note}</li>
                                        ))}
                                      </ul>}
                                    </div>
                                    <div className="table-pdf text-end-pdf">{line_item.unit != '' ? calculateAdjQuantity(line_item)+''+line_item.unit : ''}</div>
                                    {line_item.unit != '' ?
                                      <div className="table-pdf text-end-pdf">{(parseInt(line_item.remove_prices) === 0 || typeof line_item.remove_prices === 'undefined') && `$${line_item.cost}`}</div>
                                    :
                                      <div className="table-pdf text-end-pdf"></div>
                                    }
                                    {line_item.unit != '' ?
                                      <div className="table-pdf text-end-pdf">{(parseInt(line_item.remove_prices) === 0 || typeof line_item.remove_prices === 'undefined') && `$${calculateNetCost(line_item)}`}</div>
                                    :
                                      <div className="table-pdf text-end-pdf"></div>
                                    }
                                  </div>
                                </div>);
                              }
                            })}
                          </div>)
                        }
                      })}
                    </div>
                    <>
                      { pageCount == totalPages &&
                      <>
                       <div className="container-pdf">
                        <div className="w-100 row">
                          <div className="col-8 p-left notes-box">
                            <p style={{ display: "flex" }}> <img src={NotesIcon} className="notes-icon"/><b>Notes</b></p>
                            <p dangerouslySetInnerHTML={{__html: stData.notes}} className="notes-box-text"></p>
                          </div>
                          <div className="col-4 p-right">
                            <table className="table custom-total-table">
                              <thead>
                                <tr>
                                  <th scope="col" className="font-total total-background-first ">&nbsp;&nbsp;&nbsp;&nbsp;Subtotal</th>
                                  <th scope="col" className="amount-first custom-th-total-table">${subTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                                </tr>
                                {taxes.length > 0 &&
                                  taxes.map((tax, i) => {
                                    if (parseInt(tax.total) > 0) {
                                      return (<tr key={i}>
                                        <th scope="col" className="font-total font-total-small total-background">
                                          {tax.name}
                                        </th>
                                        <th scope="col" className="amount">
                                          ${tax.total}
                                        </th>
                                      </tr>)
                                    }
                                  })}
                                {markups.length > 0 &&
                                  markups.map((markup, i) => {
                                    if (parseInt(markup.total) > 0) {
                                      return (<tr key={i}>
                                        <th scope="col" className={`font-total font-total-small ${i + 1 == markups.length? "total-background-last": "total-background"}`}>
                                          {markup.name}
                                        </th>
                                        <th scope="col" className="amount">
                                          ${markup.total}
                                        </th>
                                      </tr>)
                                    }
                                  })}
                                <tr>
                                  <th scope="col" className="font-total font-total-background">TOTAL</th>
                                  <th scope="col" className="bg-pdf" >${total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                        </div>
                        </>
                      }
                      { invoice != 1 &&
                        pageCount == totalPages && financingOptions.some(option => option.is_active === 'Y') &&
                      <div className="financial_report_container" style={{backgroundColor: "#d6e1e5" , padding: "50px" , marginBlock:"9px"}}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div style={{ display: "flex" , alignItems: "center" , width: "50%" , marginBottom: "5px"}}>
                    <div style={{backgroundColor:"#f79425" , borderRadius: "50%" , padding: "5px" , marginRight: "10px"}}>
                      <img src={CameraLogo} alt="Cameralogo"/>
                    </div>
                      <p className="mb-0" style={{fontSize: "18px" ,fontWeight: "bold" , fontFamily: "Rajdhani"}}>FINANCING OPTIONS*</p>
                    </div>
                      <div className="d-flex justify-content-end">
                      {/* <img style={{width: "30%"}} src={SunLightLogo} alt="SunLightLogo"/> */}
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mt-2">
                    {financingOptions.map((option, i) => (
                        <>
                          {option.is_active == 'N' ?
                            <></>
                          :
                            <div style={{marginLeft: i === 0 ? "0px" : "20px",fontFamily: "Rajdhani" ,backgroundColor: "white" , width: "32%" , padding: "15px" , borderRadius:"10px"}}>
                              <p className="mb-0" style={{fontSize: "18px" , fontFamily: "Rajdhani" ,fontWeight: "bold"}}>{option.months} Months</p>
                              <p className="mb-0" style={{display: 'flex' , alignItems : "center" , fontFamily: "Rajdhani" ,fontWeight: "bold" , color:'#5ce1bb', fontSize: "28px"}}>{option.percentage}% = 
                              {option.percentage == '0' || option.percentage == '0.00' ?
                                <span style={{marginLeft : '5px' ,fontFamily: "Rajdhani" , fontWeight: "bold", color:"#f79425" ,fontSize:"18px"}}> No Payment No Interest</span>
                                :
                                <span style={{marginLeft : '5px' , fontFamily: "Rajdhani" , fontWeight: "bold" , color:"#f79425",fontSize: "28px"}}> {'$'+(option.percentage*total/100).toFixed(2)}</span>
                              }
                              </p>
                            </div>
                          }
                        </>
                    ))}
                  </div>
                  <div className="d-flex justify-content-between mt-4">
                    <div style={{width: "32%" , backgroundColor:"#f79425" ,borderRadius:"10px" , padding: "10px" , fontFamily: "Rajdhani" , fontWeight: "bold" , display: "flex" , justifyContent:"center" , alignItems: "center" , height:"fit-content"}}>
                      <p className="mb-0" style={{fontSize: "18px" , fontFamily: "Rajdhani" , fontWeight: "bold"}}>{"GET APPROVED NOW >"}</p>
                    </div>
                    <div className="mb-0" style={{width: "66%" , fontSize: '9px' , fontFamily: "Rajdhani" , fontWeight: "bold"}}>
                      <p style={{fontFamily: "Rajdhani", fontWeight: "bold"}}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                        vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
                        amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et</p>
                    </div>
                  </div>
                  </div>
                      }
                      <div className="footer-wrapper page-break">
                        <div className="footer-container">
                        {contact ?
                          <div className="footer-bg">{contact?.company ? contact?.company : ''} | { contact?.email } | { contact?.phone ? ' | '+formatPhoneNumber(contact?.phone) : ''}</div>
                          :
                          <div className="footer-bg"> { defContactPhoneNo ? formatPhoneNumber(defContactPhoneNo) : ''} &nbsp;</div>
                        }
                          <div className="footer-notes-right">
                                  {" "}
                          {/* {stData.footer}&nbsp; */}
                           Estimate #: {stData.ref_id}
                          </div>
                          <div className="page-number" ref={(node) => {
                              if (node) {
                                node.style.setProperty("background-color", color, "important");
                                node.style.setProperty("color", '#fff', "important");
                              }
                            }} style={{ backgroundColor: color }}> {pageCount} of {totalPages} </div>
                        </div>
                      </div>
                    </>
                    </Wrapper>)
                  }
                })}
            </MainDiv>
          </div>
        </PDFS>)}
    </>
  );
}

const LoadingContainer = styled.div`
  height:100vh;
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  background: transparent;
  .loader{
      -webkit-user-select: none;
      margin: auto;
  }
`
const PDFS = styled.div`
  width:100%;
  height:100%;
  background-color: #CCCCCC;
  padding-bottom: 30px;
  padding-top: 60px;
  .loc-sec{
    background-color: #151f24 !important;
    padding: 13px 12px;
    color: white;
    margin-bottom: 0;
    border-radius: 13px 13px 0px 0px;
  }
  .header-contact{
    font-family:Rajdhani;
    font-size:20px;
    font-weight:700;
  }
  .border-radius-none {
    border-radius: 0;
  }
  .mainDiv {
    width: 1130px;
    height: 100%;
    margin: auto;

    @media(max-width: 767px) {
      width: 100%;
      overflow: hidden;
    }
  }
  .notes-box{
    margin-bottom:20px;
  }
  .notes-box-text{
    margin-bottom:0px;
    padding-bottom:0px;
  }
  .button-section {
    border-radius: 3px;
    cursor: pointer;
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #414141;
    margin-right: 10px;
    &:hover {
      opacity: 0.5;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .button-calculator {
    cursor: pointer;
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:#7cc57c;
    img {
      width: 20px;
      height: 20px;
    }

    &:hover {
      opacity: 0.5;
    }
  }


  .button-slip {
    cursor: pointer;
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:#f7941d;
    img {
      width: 20px;
      height: 20px;
    }
    &:hover {
      opacity: 0.5;
    }
  }
`;
const printStyles = `
    @media print {
    
      @page {
        margin: 0.5in; 
      }

      .page-break {
        page-break-after: always;
      }
      .print-page-number {
        display: none !important;
      }
      .footer-wrapper{
        position: fixed !important; /* Stick the footer at the bottom */
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 0 !important; /* Remove padding for print */
      }
      .financial_report_container {
        padding: 0 !important; /* Remove padding for print */
      }
    @media screen {
      .print-page-number {
        display: none !important;
      }
    }
`;
const MainDiv = styled.div`
  width:100%;
  height:100%;
`;

const Wrapper = styled.div`
  margin-top: 1em;
  margin-bottom: 2em;
  padding: 50px;
  background-color: #ffffff;
  position: relative;
  
  .container-pdf {
    padding: 0px !important;
  }

  @media print {
    padding: 0 !important; /* Remove padding during print */
  }

  .financial_report_container {
    margin-inline: -50px;
    margin-block: 9px;
  }

  .cut-separator {
    position: relative;
    margin: 0 0 2em 0;
  }

  .cut-separator .icon {
    position: absolute;
    left: -12px;
    top: 19px;
    font-size: 1.6em;
  }

  .cut-separator .text {
    padding-bottom: 1em;
    color: #9d9fa2;
    border-bottom: 2px dashed #9d9fa2;
    width: 100%;
    text-align: center;
  }

  .p-left {
    padding-left: 0px;
  }

  .p-right {
    padding-right: 0px;
  }

  .quantity-row-pdf {
    display: -webkit-box;
  }
  
  .table-pdf {
    color: #212e34;
    font-size: 14px;
    width: 15%;
    padding: 5px 10px;
    border-right: 1px solid #d6e1e5 !important;
    border-bottom: 1px solid #d6e1e5 !important;
    word-wrap: break-word;
  }
  .bg-white{
    background-color: #fff !important;
  }
  .bg-grey{
    background-color: #D6E1E540 !important;
  }
  .table-pdf.col-name {
    width: 55%;
    padding:8px 10px;
  }
  
  .amount {
    border-left: 1px solid #d6e1e5 !important;
    border-right: 1px solid #d6e1e5 !important;
    border-top: 1px solid #d6e1e5 !important;
    font-size: 14px !important;
    font: normal normal 600 16px Rajdhani !important;
    letter-spacing: 0.42px !important;
    color: #212e34 !important;
  }
  .amount-first {
    border-left: 0px solid #d6e1e5 !important;
    border-right: 0px solid #d6e1e5 !important;
    border-top: 0px solid #d6e1e5 !important;
    font-size: 14px !important;
    border-top-right-radius: 10px !important;
    border-bottom: 1px solid #d6e1e5 !important;
    text-align: end !important;
    font: normal normal 600 16px Rajdhani !important;
    letter-spacing: 0.42px;
    color: #212e34 !important;
  }
  .font-total-small {
    font-size:15px !important;
  }  
  .font-total, .bg-pdf, .amount {
    border: none;
    text-align: end;
  }
  .font-total-background {
    background: #212e34 0% 0% no-repeat padding-box !important;
    border-bottom-left-radius: 10px;
    font: normal normal bold 20px Rajdhani !important;
    letter-spacing: 0.62px !important;
    color: #ffffff !important;
    text-transform: uppercase;
    opacity: 1 !important;
  }
  .total-background {
    background: #d6e1e5 0% 0% no-repeat padding-box;
    opacity: 1;
    font: normal normal bold 20px Rajdhani;
    letter-spacing: 0.42px;
    color: #212e34;
    text-transform: uppercase;
    opacity: 1;
    border-bottom: 1px solid #fff;
    width: 10px;
  }
  .total-background-first {
    background: #d6e1e5 0% 0% no-repeat padding-box !important;
    border-radius: 10px 0px 0px 0px !important;
    opacity: 1;
    font: normal normal bold 20px Rajdhani !important;
    letter-spacing: 0.42px;
    color: #212e34 !important;
    text-transform: uppercase !important;
    opacity: 1 !important;
    border-bottom: 1px solid #fff !important;
    width: 10px ;
  }
  .total-background-last {
    background: #d6e1e5 0% 0% no-repeat padding-box;
    opacity: 1;
    font: normal normal bold 20px Rajdhani;
    letter-spacing: 0.42px;
    color: #212e34;
    text-transform: uppercase;
    opacity: 1;
    width: 10px;
  }
  .bg-pdf {
    background: #f7941d !important;
    font-size: 14px !important;
    border-radius: 0px 0px 10px 0px !important;
    font: normal normal 600 20px Rajdhani !important;
    letter-spacing: 0.62px !important;
    color: #212e34 !important;
  }

  .b-left {
    border-left: 1px solid #d6e1e5;
  }

  .b-top {
    border-top: 1px solid #d6e1e5;
  }

  .width-80-l {
    width: 80%;
    float: left;
  }

  .width-20-r {
    width: 20%;
    float: right;
    text-align: right;
  }
  .footer-wrapper {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0px 50px;
    box-sizing: border-box;
    width: 100%;
    color: #fff !important;
  }
  .footer-notes-right {
    padding: 5px !important;
    background-color: inherit !important;
    font-family: Rajdhani !important;
    font-size: 16px !important;
    letter-spacing: 0.26px !important;
    text-align: end !important;
    color:#fff !important;
    font-weight:500 !important
  }
  .footer-container {
    color: #fff !important;
    background-color: #212E34 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    border-bottom-right-radius: 13px !important;
    border-bottom-left-radius: 10px !important;
    color:#fff !important;
  }
  @media print {
    .page-break {
      page-break-after: always !important;
    }
  }
  .footer-bg {
    width: 71% !important;
    float: left !important;
    padding: 5px !important;
    background-color: transparent !important;
    color: #ffffff !important;
    font-family: Rajdhani !important;
    letter-spacing: 0.42px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  .footer-notes {
    width: 68% !important;
    float: left !important;
    padding: 5px !important;
    background-color: inherit !important;
    text-align: end !important;
  } 
  
  .page-number {
    width: 10% !important;
    float: right !important;
    padding: 5px 16px !important;
    background-color: inherit !important;
    border-bottom-right-radius: 10px !important;
    background-color: #f7941d !important;
    min-width: 60px !important;
    text-align: end !important;
    min-height: 34px !important;
  } 
  .estimate-number {
    width: 100%;
    float: right;
    padding: 5px;
    background-color: inherit;
  }
  .notes {
    margin-top: 10px;
    list-style-type: none;
    line-height: 35px;
  }

  .m-bottom {
    margin-bottom: 20px;
  }
  .custom-bg-dark-pdf-middle{
    background-color: #212e34 !important;
  }
  .custom-bg-dark-pdf-last{
  background-color: #212e34 !important;
  }
  .custom-bg-dark-pdf {
    background-color: #212e34 !important;
  }
  .custom-bg-grey-pdf{
    background-color: #D6E1E5 !important;
  }
  .custom-bg-grey-pdf-middle{
    background-color: #D6E1E5 !important;
  }
  .custom-bg-grey-pdf-last{
    background-color: #D6E1E5 !important;
  }
  .custom-total-table{
    border: 1px solid #d6e1e5 !important;
    border-radius: 10px !important;
    overflow: hidden !important;
    display: flex !important;
  }
  .custom-th-total-table{
    width: 10000px;
  }
  }
  
`;